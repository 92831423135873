
@media only screen and (max-width: 2220px) and (min-width: 992px){
   .gamezong{
       width: 100%;
       height: 100%;
   }
   .gametou{
    width: 100%;
    height: auto;
    background: #e9e9ea;
   }
   .gametou2{
    width: 88.8%;
    max-width: 1122px;
    padding: 20px 0;
    margin: 0 auto;
    overflow: hidden;
   }
   .gamezz{
    margin: 58px auto 0
   }
   .gamecontent{
    width: 89.3333%;
    max-width: 1122px;
    min-height: 567px;
    margin: 53px auto 0;
   }
   .gamecontent1{
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
    border-top: 1px solid #e9e9ea;
   }
   .gamecontett{
    width: 100%;
    margin: 4.5% 0;
    font-size: 0;
    text-align: left;
    display: block;
    display: flex;
   }
   .gamelis{
    max-width: calc(100% - 200px - 24px);
    margin-left: 24px;
    min-width: 100px;
    display: inline-block;
    vertical-align: middle;
    width: 100%;
    color: #272931;
    font-size: 12px;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
   }
   .yyimg{
    width: 75px;
    top: 16px
   }
}   
@media only screen and (max-width:768px){
    .gamezong{
        width: 100%;
        height: 100%;
    }
    .gametou{
     width: 100%;
     height: auto;
     background: #e9e9ea;
    }
    .gametou2{
     width: 88.8%;
     max-width: 1122px;
     padding: 20px 0;
     margin: 0 auto;
     overflow: hidden;
    }
    .gamezz{
     margin: 58px auto 0
    }
    .gamecontent{
     width: 89.3333%;
     max-width: 1122px;
     min-height: 567px;
     margin: 53px auto 0;
    }
    .gamecontent1{
     margin: 0;
     padding: 0;
     border: 0;
     outline: none;
     border-top: 1px solid #e9e9ea;
    }
    .gamecontett{
     width: 100%;
     margin: 4.5% 0;
     font-size: 0;
     text-align: left;
     display: block;
     display: flex;
    }
    .gamelis{
     max-width: calc(100% - 200px - 24px);
     margin-left: 24px;
     min-width: 100px;
     display: inline-block;
     vertical-align: middle;
     width: 100%;
     color: #272931;
     font-size: 12px;
     line-height: 20px;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space: nowrap;
    }
    .yyimg{
     width: 75px;
     top: 16px
    }
    
}