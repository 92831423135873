* {
    padding: 0;
    margin: 0;
    text-decoration: none;
}

html,
body,
#root {
    width: 100%;
    height: 100%;
}

@media only screen and (min-width: 415px) {
    .button_sou {
        width: 70px;
        margin-left: 10px;
        cursor: pointer;
        padding: 0;
        background: 0 0;
        background-color: #4e6ef2;
        border-radius: 0 10px 10px 0;
        font-size: 17px;
        color: #fff;
        box-shadow: none;
        font-weight: 400;
        border: none;
        outline: 0;
    }

    .cont_li {
        width: 70rem;
        height: 100%;

        margin: 0 auto;
        margin-top: 2rem;
    }

    .header {
        width: 100%;
        height: 6rem;
        border: 1px solid #ccc;
        background: white;
    }

    .anticon svg {
        display: none !important;
    }

    .weer {
        margin-left: 3rem;
    }

    .content {
        width: 100%;
        height: 100%;
    }

    .headerT {
        width: 70rem;
        height: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: white;
    }

    .header_left {
        width: 40%;
    }

    .header_right {
        width: 60%;
        text-align: right;
    }

    .content_right {
        margin-right: 2.5rem;
        color: #ccc;
        font-size: 16px;
        font-weight: bold
    }

    .cont_zong {
        width: 89.3333%;
        max-width: 805px;
        min-height: 568px;
        margin: -150px auto 0;
        margin: -230px auto 0;
        min-height: 1104px;
    }

    .cont_nr {
        margin-bottom: 30px;
        color: white;
    }

    .neir {
        color: white;
    }

    .cont_box {
        width: calc(100% - 2px);
        border: 1px solid #dddddd;
        border-top: 7px solid #462dce;
        background: #fff;
        padding-bottom: 42px;

    }

    .active {
        color: black;
    }

    .swiper-wrapper {
        margin: 0;
        width: 100%;
    }

    .contentStyle {
        width: 100%;
        height: 20rem;
        color: #fff;
        line-height: 30rem;
        text-align: center;
        background: #364d79;
    }

    .contentStyle1 {
        height: 20rem;
        color: #fff;
        line-height: 30rem;
        text-align: center;
        background: #364d79;
    }

    .content_zong {
        width: 75rem;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
        padding-left: 3.1rem;
    }

    .conntent_big {
        width: 1100px;
        height: 350px;
        overflow: hidden;
        border: 1px solid #ccc;
    }

    .content_xibig {
        width: 1100px;
        height: 350px;
        overflow: hidden;
    }

    .content_lie {
        width: 2000px;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        /* overflow: hidden; */
        z-index: 1;
    }

    .content_lie2 {
        position: relative;
        width: 18%;
        height: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .content_one {
        width: 23rem;
        height: 33%;
    }

    .content_xinyu {
        width: 20rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        border-left: 1px solid #ccc;
    }

    .content_xin {
        width: 80rem;
        height: 21.5rem;
        margin: 0 auto;
        display: flex;
        border: 1px solid #ccc;
        border-left: 1px solid #ccc;
        margin-top: 2rem;
    }

    .content_xin1 {
        width: 4500px;
        height: 21.5rem;
        margin: 0 auto;
        display: flex;
        border: 1px solid #ccc;
        border-left: 1px solid #ccc;
        overflow: hidden;
    }

    .yuye {
        width: 10rem;
        height: 40px;
        margin: 0 auto;
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        background: #272931;
        color: white;
    }

    .dit_clis {
        display: flex;
        padding-left: 40px;
    }

    .dit_clist {
        width: 147px;
        padding-left: 10px;
    }

    .p {
        background: #ccc;
        margin-left: 10px;
        border-radius: 15px;
        padding: 2px;
        ;
    }

    .qian {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: 10px;
    }

    .contennt_tong {
        display: flex;
    }

    .content_yue {
        width: 250px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border-left: 1px solid #ccc;
    }

    .content_kng {
        width: 23rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;
        margin: 0 auto;
    }

    .border_xia {
        width: 58px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        border: 2px solid #462dce;
        color: #462dce;
        font-size: 12px;
        border-radius: 20px;
        text-align: center;
        line-height: 28px;
        margin-left: 1rem;
        transition: all .3s;
    }

    .maoding {
        background: white;
    }

    .dit_s {
        width: 5rem;
        cursor: pointer;
        height: 5rem;
        border-radius: 15px;
    }

    .summary {
        color: #999999;
    }

    .footer {
        width: 100%;
        height: 330px;
        background: #282f3e;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .footer_cont {
        width: 1000px;
        height: 270px;
    }

    .footer_top {
        width: 100%;
        height: 100px;
        align-items: center;
        display: flex;
        justify-content: space-between;
        color: #0089bc;
        padding-bottom: 2rem;
    }

    .footer_right {
        display: flex;
    }

    .nr {
        border-bottom: 1px solid #0089bc;
    }

    .nr1 {
        border-bottom: 1px solid #0089bc;
        margin-left: 20px;
    }

    .footer_bottom {
        width: 100%;
        height: 200px;
        border-top: 1px solid #ccc;
        color: #fff;
    }

    .bnr {
        margin-top: 15px;
    }

    .kuang {
        width: 89.3333%;
        max-width: 1082px;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        color: #000;
        background: #fff;
        border: 1px solid #dddddd;
        box-shadow: 0 0 0 3px rgb(211 211 211 / 20%);
        display: block;
        margin: 30px auto;
        margin-bottom: 30px;

    }

    .shop_one {
        width: 90%;
        border-bottom: 1px solid #eeeeef;
        padding: 40px 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }

    .shop_one_left {
        display: inline-block;
        width: calc(99% - 70px - 10px);
        display: flex;
        align-items: center;
    }

    .shop_img {
        width: 74px;
        height: 74px;
        margin-right: 20px;
    }

    .shop_xiaz {
        width: 70px;
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        border: 2px solid #462dce;
        border-radius: 20px;
        margin-left: 10px;
        transition: all .3s;
        margin: 0 auto;
        text-align: center;
    }

    .swiper-container {
        height: 35rem !important;
    }

    .cont_limg {
        width: 220px;
        height: 220px;
    }

    .cont_list {
        width: 100%;
        display: flex;
        align-items: center;

        justify-content: space-between;
    }

    .cont_button {
        width: 12%;
        height: 30px;
        background: #fff;
        border: 1px solid #462dce;
        border-radius: 15px;
        color: #462dce;
        font-size: 15px;
        text-align: center;
        transition: all .3s;
        line-height: 30px;
        margin-left: 2%;
    }

    .cont_toutou {
        height: 41px;
        overflow: hidden;
        font-size: 0;
        margin-bottom: 4%;
        display: flex;

    }

    .cont_milin {
        width: 29%;
        height: 41px;
        font-size: 20px;
        color: #fff;
        line-height: 41px;
        text-align: center;
        background: #462dce;
    }

    .cont_mihou {
        width: 71%;
        height: 41px;
        background: #f3f3f3;
        color: #333;
        font-size: 20px;
        line-height: 41px;
        text-indent: 5.5%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0 auto;
        align-items: center;
    }

}

@media only screen and (max-width:414px) {
    .button_sou {
        width: 150px;
        margin-left: 10px;
        cursor: pointer;
        padding: 0;
        background: 0 0;
        background-color: #4e6ef2;
        border-radius: 0 10px 10px 0;
        font-size: 17px;
        color: #fff;
        box-shadow: none;
        font-weight: 400;
        border: none;
        outline: 0;
    }

    .cont_limg {
        width: 95px;
        height: 95px;
    }

    .cont_qqq {
        margin-left: 1rem;
    }

    .cont_button {
        width: 12%;
        height: 22px;
        background: #fff;
        border: 1px solid #462dce;
        border-radius: 15px;
        color: #462dce;
        font-size: 12px;
        text-align: center;
        line-height: 22px;
        margin-left: 2%;
        margin-right: 1rem;
    }

    .cont_list {
        width: 100%;
        display: flex;
        align-items: center;

        justify-content: space-between;
    }

    .cont_li {

        height: 100%;

        margin: 0 auto;
        margin-top: 2rem;
    }

    .app {
        width: 100%;
    }

    .ccoonn {
        flex: 1;
    }

    .swiper-wrapper {
        margin: 0;
        width: 100%;
    }

    .header {
        width: 100%;
        height: 4rem;
        display: flex;
    }

    .header_right {
        display: none;
    }

    .headerT {
        width: 100%;
        height: 100%;
        display: flex;
        background: white;
        align-items: center;
        justify-content: space-around;
        border-bottom: 1px solid #ccc;
    }

    .swiper-container {
        height: 12rem !important;
    }

    .content_right {
        padding: 10px;
    }

    .conntent_contenn {
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        overflow-y: auto;
    }

    .contentStyle {
        width: 100%;
        height: 10rem;
    }

    .content_xin {
        max-width: 1122px;
        margin: 0 auto;
        background: #fff;
        border-bottom: 1px solid #ededed;
        box-shadow: 0 5px 5px rgb(211 211 211 / 20%)
    }

    .content_xinyu {
        width: 100%;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }

    .featureList_p {
        margin-left: 1rem;
    }

    .biaot {
        margin-left: 1rem;
    }

    .content_xibig {
        width: 100%;
        height: 280px;
        overflow: scroll;

    }

    .detail_br {
        width: 90%;
        color: #737373;
        font-size: 12px;
        line-height: 21px;
        margin: 0 auto;
    }

    .content_xin1 {
        width: 3450px;
        height: 270px;
        margin: 0 auto;
        display: flex;
        margin-left: 1rem;
        z-index: 1;

    }

    .contennt_tong {
        display: flex;
    }

    .content_yue {
        width: 12rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 1px solid #ccc;
    }

    .dit_s {
        width: 5rem;
        cursor: pointer;
        height: 80px;
        border-radius: 15px;
    }

    .qian {
        display: none;
    }

    .yuye {
        width: 4rem;
        height: 30px;
        margin: 0 auto;
        align-items: center;
        display: flex;
        justify-content: space-evenly;
        background: #272931;
        color: white;
    }

    .content_zong {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
    }

    .conntent_big {
        width: 90%;
        height: 350px;
        overflow: scroll;
        border: 1px solid #ccc;
        margin-left: 1rem;

    }

    .content_lie {
        width: 2000px;
        height: 100%;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        z-index: 1;

    }

    .content_lie2 {
        width: 350px;
        height: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

    }

    .content_one {
        width: 370px;
        height: 33%;

    }

    .content_kng {
        width: 300px;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2px;
    }

    .dit_clis {
        padding-left: 10px;
        display: flex;
    }

    .dit_clis img {
        width: 70px;
        height: 70px;
    }

    .dit_s {
        width: 5rem;
        cursor: pointer;
        height: 80px;
        border-radius: 15px;
    }

    .dit_clist {
        width: 147px;
    }

    .summary {
        color: #999999;

    }

    .border_xia {
        width: 58px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        border: 2px solid #462dce;
        color: #462dce;
        font-size: 12px;
        border-radius: 20px;
        text-align: center;
        line-height: 28px;
        transition: all .3s;

    }

    .kuang {
        width: 60.3333%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 14px;
        color: #000;
        background: #fff;
        border: 1px solid #dddddd;
        box-shadow: 0 0 0 3px rgb(211 211 211 / 20%);
        display: block;

        margin-left: 4rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .footer {
        width: 100%;
        height: 300 px;
        background: #282f3e;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .footer_cont {
        width: 350px;
        height: 250px;
    }

    .footer_top {
        width: 90%;
        height: 50px;
        align-items: center;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        color: #0089bc;
    }

    .footer_right {
        display: flex;
    }

    .nr {
        border-bottom: 1px solid #0089bc;

    }

    .nr1 {
        border-bottom: 1px solid #0089bc;
        margin-left: 20px;
    }

    .footer_bottom {
        width: 90%;
        height: 200px;
        border-top: 1px solid #ccc;
        margin: 0 auto;
        align-items: center;
        color: #fff;
    }

    .bnr {
        margin-top: 15px;
    }

    .contentStyle {
        height: 10rem;
        color: #fff;
        line-height: 30rem;
        text-align: center;
        background: #364d79;
    }

    .cont_zong {
        width: 89.3333%;
        max-width: 300px;

        margin: -113px auto 0;
        min-height: 650px;
    }

    .cont_nr {
        margin-bottom: 10px;
        color: white;
    }

    .neir {
        color: white;
    }

    .cont_box {
        width: calc(100% - 2px);
        border: 1px solid #dddddd;
        border-top: 7px solid #462dce;
        background: #fff;
        padding-bottom: 22px;
    }

    .active {
        color: black;
    }

    .contentStyle {
        height: 10rem;
        color: #fff;
        line-height: 10rem;
        text-align: center;
        background: #364d79;
    }

    .contentStyle1 {
        height: 7rem;
        color: #fff;
        line-height: 10rem;
        text-align: center;
        background: #364d79;
    }

    .shop_one {
        width: 90%;
        border-bottom: 1px solid #eeeeef;
        padding: 10px 0;
        margin: 0 auto;
        display: flex;
        align-items: center;
    }

    .shop_one_left {
        display: inline-block;
        width: calc(99% - 70px - 10px);
        display: flex;
        align-items: center;
    }

    .shop_img {
        width: 56px;
        height: 56px;
        margin-right: 20px;
    }

    .shop_xiaz {
        width: 49px;
        height: 22px;
        background: #fff;
        border: 1px solid #462dce;
        border-radius: 15px;
        color: #462dce;
        font-size: 11px;
        text-align: center;
        line-height: 22px;
        margin-left: 5px;
    }

    .ant-image-preview-img {
        width: 70%;
        height: 70%;
    }

    .img_center {
        margin: 0 auto;
    }
}