
@media only screen and (max-width: 2220px) and (min-width: 992px){
    .detail_zong{
        height: 100%;
        width: 100%;
        
    }
    .weer{
        margin-left: 3rem;
       }
    .dit_s{
        width: 112px;
        margin-right: 20px;
    }
    .detail_ab{
        width: 198px;
        height: 43px;
         line-height: 43px;
         text-align: center;
        background: #5338ee;
        color: white;
        display: block;
    }
   .detail_content{
       width: 100%;
       height: 100%;
   }
   /* .detail_header{
    width: 100%;
    height: 200px;
    position: relative;
    filter: blur(4px);
   } */
    .detail_tou{
        width: 100%;
        position: relative;
    
      
        max-width: 1122px;
        height: 200px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        
    }
    .detail_tou1 h2{
        color: #fff;
        font-size: 20px;
    }
    .detail_tou1{
        top: 80px;
        display: flex;
        align-items: center;
        color: #737373;
        font-size: 20px;
    }
    .detail_br{
        color: #737373;
        
    }
    .detail_nr{
        width: 100%;
        max-width: 1122px;
        height: auto;
        padding: 20px 0;
        margin: 0 auto;
    }
    .detail_nr1{
        width: 90%;
        margin: 0 auto;
        padding: 0;
    border: 0;
    outline: none;
  
    }
    .detail_nr1 p{
        color: #737373;
    }
    .detail_gw{
        width: 100%;
        height: 120px;
    }
    .detail_gw1{
        width: 100%;
        height: 100%;
        background: #fff;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        box-shadow: 0 0 5px 5px rgb(211 211 211 / 20%);
        max-width: 1122px;
        margin: 0 auto;
      
        text-align: left;
    }
    .detail_gw2{
        width: 100%;
        height: 100%;
        margin: 0 auto;
      
        overflow: hidden;
    }
    .detail_gw3{
        width: auto;
        height: 100%;
     
    }
    .detail_gw4{
        width: 100%;
     
        margin: 0 auto;
        display: flex;
    }
    .detail_a{  
        width: 80px;
        height: 65px;
        margin: 27.5px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .detail_span{
        height: 65px;
        display: flex; 
    }
    .detail_a img{
        height: 50px;
        width: 50px;
        padding-bottom: 5px;
    }
    .detail_featureList{
        width: 100%;
        max-width: 1122px;
        margin: 0 auto;
    }
    .featureList_p{
        line-height: 50px;
        color: #000;
        font-size: 18px;
        margin: 20px 0 20px 20px;  
    }
    .features_img{
        width: 100%;
        height: 230px;
        height: 350px;
    }
    .detail_big{
        width: 1150px;
        height: 100%;
        display: flex;
        overflow: hidden;
      
    }
    .detail_pig{
        width: 4900px;
        height: 100%;
        display: flex;
       align-items: center;
    }
    .swiper-wrapper{
        width: 25%;
        height: 100%;
        margin: 10px;
    }
    .ant-image{
        width: 100%;
        height: 100%;
    }
    .swiper-wrapper img{
        width: 15rem;
        height: 100%;
    }
    .ant-image-mask{
       
    }
    .detail_categorys{
        width: 100%;
        max-width: 1122px;
        margin: 0 auto;
    }
    .ant-tabs-tab-btn{
        color: #737373;
        width: 105px;
        line-height: 50px;
        font-size: 18px;
        text-align:center
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #000;
    }
    .gheng{
        width: 600px !important
    }
    .gshu{
        width: 250px !important
    }
    .anticon svg {
        display: none!important;
    }
    .weer{
     margin-left: 3rem;
    }
}   
@media only screen and (max-width:768px){
    .detail_zong{
        height: 100%;
        width: 100%;
    }
    .anticon svg {
        display: none!important;
    }
    
    .gheng{
        width: 350px !important
    }
    .gshu{
        width: 150px !important
    }
    .dit_s{
        width: 102px;
        height: 80px;   
        margin-right: 20px;
    }
    .detail_a img{
        height: 50px;
        width: 50px;
        padding-bottom: 5px;
    }
    .swiper-wrapper img{
        width: 250px;
        height: 95%;
    }
    .detail_ab{
        width: 100px;
        height: 25px;
         line-height: 25px;
         text-align: center;
        background: #5338ee;
        color: white;
        display: block;
    }
    .detail_content{
        width: 100%;
        height: 100%;
    }
   
    .detail_tou{
        width: 100%;
        position: relative;
       
        left:0px;
        max-width: 375px;
        height: 110px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .detail_tou1 h2{
        color: #f3f3f3;
        font-size: 15px;
        display: inline;
    }
    .detail_tou1{
        width: 200px;
        top: 80px;
        display: flex;
        align-items: center;
        color: #737373;
        font-size: 15px;
    }
    .detail_br{
        color: #737373;
    }   
    .detail_nr{
        width: 100%;
        max-width: 425px;
        height: auto;
        padding: 20px 0;
        margin: 0 auto;
    }
    .detail_nr1{
        width: 90%;
        margin: 0 auto;
        padding: 0;
        border: 0;
        outline: none;
  
    }
    .detail_nr1 p{
        color: #737373;
    }

    .detail_gw{
        width: 100%;
        height: 80px;
    }
    .detail_gw1{
        width: 100%;
        height: 100%;
        background: #fff;
        border-top: 1px solid #dddddd;
        border-bottom: 1px solid #dddddd;
        max-width: 1122px;
        margin: 0 auto;
        text-align: left;
    }
    .detail_gw2{
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow: hidden;
    }
    .detail_gw3{
        width: auto;
        height: 100%;
    }
    .detail_gw4{
        width: 100%;
        margin: 0 auto;
        display: flex;
    }
    .detail_a{
        width: 80px;
        height: 65px;
        margin: 16px 5px;
        display: flex;
        flex-direction: column;
        align-items: center;

    }
    .detail_span{
        height: 65px;
        display: flex; 
    }
    .detail_a img{
        height: 40px;
        width: 40px;
      
    }
    .detail_featureList{
        width: 100%;
        max-width: 1122px;
        margin: 0 auto;
    }
    .featureList_p{
        line-height: 50px;
        color: #000;
        font-size: 18px;
        
    }
    .features_img{
  
        height: 220px;
    }       
    .detail_big{
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;
    }
    .detail_pig{
        width: 3200px;
        height: 100%;
        display: flex;
        align-items: center;
    }
    .swiper-wrapper{
        width: 25%;
        height: 100%;
        margin: 10px;
    }
    .swiper-wrapper img{
        height: 95%;
        width: 120px;
    }
    .ant-image{
        width: 100%;
        height: 100%;
    }       
}